import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';
import Nav from './Nav/Nav';
import Footer from './Nav/Footer';
import Home from './home/home';
import Privacy from './Pages/Privacy';
import WebFont from 'webfontloader';
import {useEffect} from 'react';
import FAQS from './Pages/FAQS';

function App() {

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Rajdhani']
      }
    });
   }, []);

   const Salesteam = [
    {user: 'Jake', code: 'q7R9pXtC2e'},
    {user: 'Eli', code: 'J2fHkP5sRw'},
    {user: 'Hadley', code: 'X1gRzN8wQs'},
    {user: 'Callie&Kyle', code: 'u8E2oHvK5w'},
    {user: 'Gabe Harvey', code: 'z6D1sNpW8r'},
    {user: 'Mason Skinner', code: '6a9ShbhK'},
    {user: 'Yard Sign', code: 'AGyLBzhs'},
    {user: 'Van Wrap', code: '4684oErp'}
  ];
   const queryParams = new URLSearchParams(window.location.search);
   if(queryParams.get('refid') != "")
   {
    window.referId = queryParams.get('refid');
    window.salesmen = "";
    Salesteam.forEach(user => {
      if(user.code == window.referId){
        window.salesmen = user.user;
        return;
      } 
    });
    if(window.salesmen == ""){
      window.salesmen = "Non Referrer";
    }
    //window.referId = "";
   }


  return (
    <div style={{fontFamily: 'Rajdhani'}}>
      
      <Router>
      <Nav />
        <Routes>
          <Route index element={<Home />}></Route>
          <Route path="/" element={<Home />}></Route>
          <Route path="privacypolicy" element={<Privacy />}></Route>
          <Route path="FAQS" element={<FAQS />}></Route>
        </Routes>
      <Footer />
      </Router>
      
      </div>

  );
}

export default App;
